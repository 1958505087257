import { graphql, useStaticQuery } from "gatsby";

export const useGviconfigurator = () => {
  const { gviconfigurator } = useStaticQuery(graphql`
    {
      gviconfigurator: allWp {
        nodes {
          gVIConfigurator {
            settings {
              cfgKey
              cfgValue
              id
            }
          }
        }
      }
    }
  `);

  const settings = gviconfigurator?.nodes
    ?.flatMap((item) => item?.gVIConfigurator?.settings)
    ?.map((result) => ({
      cfgKey: result?.cfgKey,
      cfgValue: JSON?.parse(result?.cfgValue) ?? result?.cfgValue
    }))
    ?.reduce(
      (arr, arrValue) => ({
        ...arr,
        [arrValue?.cfgKey?.trim()]: arrValue.cfgValue
      }),
      {}
    );

  return settings ?? {};
};
