import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
          site_url
          nav_title
          sec_nav_title
          title
          author
          theme
          brochures {
            brochure_dl
            travel_dl
          }
          social_media {
            blog
            facebook
            instagram
            linkedin
            tiktok
            twitter
            whatsapp
            youtube
            google_scholar
          }
        }
      }
    }
  `);

  return site;
};
